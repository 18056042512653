import { Component, OnInit, inject } from '@angular/core';
import { FoiComponent } from '../../foi/foi.component';
import { SharedService } from '../../../../_services/shared.service';

@Component({
  selector: 'app-foi-courtex',
  standalone: true,
  imports: [FoiComponent],
  templateUrl: './foi-courtex.component.html',
  styleUrl: './foi-courtex.component.scss',
})
export class FoiCourtexComponent implements OnInit {
  sharedService = inject(SharedService);
  ngOnInit(): void {
    this.sharedService.readOnlyMode.set(true);
  }
}
