import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCase1',
  standalone: true,
})
export class CamelCase1Pipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value) {
      value = this.splitByChar(value, '_');
    }
    return value;
  }

  splitByChar(str: string, char: string) {
    if (str && char) {
      if (str.includes(char)) {
        const arr = str.split(char);
        if (arr && arr.length) {
          for (let i = 0; i < arr.length; i++) {
            arr[i] = this.camalize(arr[i]);
          }
          str = arr.join(' ');
        }
      } else {
        str = this.camalize(str);
      }
    }
    return str;
  }

  camalize(str: string) {
    if (str) {
      str = str.toLowerCase();
      str = str.charAt(0).toUpperCase() + str.substring(1);
    }
    return str;
  }
}
