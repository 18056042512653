import { Component } from '@angular/core';
import { ClassificationComponent } from './classification/classification.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-sorting',
  standalone: true,
  imports: [ClassificationComponent, RouterOutlet],
  templateUrl: './sorting.component.html',
  styleUrl: './sorting.component.scss',
})
export class SortingComponent {}
