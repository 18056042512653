import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../_services/shared.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent {
  isLoading: boolean = false;
  loadingSubscription: Subscription = new Subscription();

  constructor(private sharedService: SharedService) {}
  ngOnDestroy(): void {
    this.loadingSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loadingSubscription = this.sharedService.loading.subscribe(
      (status) => {
        this.isLoading = status;
      }
    );
  }
}
