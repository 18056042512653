import { CommonModule, DatePipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { IDashboardResponse } from '../../_interfaces/entity';
import { ApiService } from '../../_services/api.service';
import { SharedService } from '../../_services/shared.service';
import { DashboardFilterComponent } from '../_common/dashboard-filter/dashboard-filter.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [FormsModule, CommonModule, DashboardFilterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  apiService = inject(ApiService);
  dateFrom!: string | null;
  dateTo!: string | null;
  caseName: string = '';
  caseList: any = [];
  sessionStored: boolean = false;
  maxDate!: string | null;
  datePipe = inject(DatePipe);
  sharedService = inject(SharedService);

  baseURL = environment.baseUrlDashboard;

  pageDefinition: any = {
    "id": "Angeion",
    "role": "string",
    "users": "user",
    "pageName": "Angeion",
    "clientId": "Angieon_uuid",
    "config": {
      "grid_x": 12,
      "grid_y": 7
    },
    "graphs": [
      {
        "chartId": "chart-1",
        "title": "",
        "clientId": "Angieon_uuid",
        "source": 'angeionData',
        "session_id": "",
        "chart_type": "numCards",
        "property": {
          "header": "Total Packages",
          "mainNum": 0,
          "secondHeader": "",
          "secondNum": 0,
          "grapgh": "string",
          "bgColor": "#C2F6EE",
          "color": "#000",
          "valueType": "count",
          "valueTypeSecondNum": "count"
        },
        "inputs": [{
          "column_names": [],
          "column_value": "Package Id",
          "aggregate": ['Package Id', 'count', '', 'normal_aggregation'],
          "time_aggregate": false,
          "filter_pairs": [
          ],

          "cardType": "Normal",
          "isSingle": true
        }],
        "layout": {
          "position_x": 1,
          "position_y": 1,
          "w": 2,
          "h": 1
        }
      },
      {
        "chartId": "chart-new",
        "title": "",
        "clientId": "Angieon_uuid",
        "source": 'angeionData',
        "session_id": "",
        "chart_type": "numCards",
        "property": {
          "header": "New Packages",
          "mainNum": 0,
          "secondHeader": "",
          "secondNum": 0,
          "grapgh": "string",
          "bgColor": "#FCE7DB",
          "color": "#000",
          "valueType": "count",

        },
        "inputs": [{
          "column_names": [],
          "column_value": "Package Id",
          "aggregate": ['Package Id', 'count', '', 'normal_aggregation'],
          "time_aggregate": false,
          "filter_pairs": [
            ["Status", "NEW", "="]
          ],
          "cardType": "Normal",
          "isSingle": true
        }],
        "layout": {
          "position_x": 3,
          "position_y": 1,
          "w": 2,
          "h": 1
        }
      },
      {
        "chartId": "chart-1",
        "title": "",
        "clientId": "Angieon_uuid",
        "source": 'angeionData',
        "session_id": "",
        "chart_type": "numCards",
        "property": {
          "header": "Packages Classified",
          "mainNum": 0,
          "secondHeader": "Average DC Time",
          "secondNum": 0,
          "grapgh": "string",
          "bgColor": "#C3E1FF",
          "color": "#000",
          "valueType": "",
          "valueTypeSecondNum": "",
        },
        "inputs": [{
          "column_value": "Time For Doc Classification",
          "aggregate_2": ['Time For Doc Classification', 'mean', 'date', 'time_aggregation'],
          "aggregate": ['Package Id', 'count', '', 'normal_aggregation'],
          "time_aggregate": true,
          "filter_pairs_2": <any>[],
          "filter_pairs": [
            ["isClassified", true, "="]
          ],
          "cardType": "Trend2",
          "isSingle": false
        }],
        "layout": {
          "position_x": 1,
          "position_y": 2,
          "w": 2,
          "h": 1
        }
      },
      {
        "chartId": "chart-1",
        "title": "",
        "clientId": "Angieon_uuid",
        "source": 'angeionData',
        "session_id": "",
        "chart_type": "numCards",
        "property": {
          "header": "Average FOI completion time",
          "mainNum": 0,
          "secondHeader": "",
          "secondNum": 0,
          "grapgh": "string",
          "bgColor": "#c0afff",
          "color": "#313a46",
          "valueType": "",
          "valueTypeSecondNum": "",
        },
        "inputs": [{
          "column_value": "Time For Doc Extraction",
          "aggregate": ['Time For Doc Extraction', 'mean', 'date', 'time_aggregation'],
          "time_aggregate": true,
          "filter_pairs": [

          ],
          "cardType": "Time1",
          "isSingle": true
        }],
        "layout": {
          "position_x": 3,
          "position_y": 2,
          "w": 2,
          "h": 1
        }
      },
      {
        "chartId": "chart-3",
        "title": "",
        "clientId": "Angieon_uuid",
        "source": "angeionData",
        "session_id": "",
        "chart_type": "AdvPie",
        "property": {
          "common": {
            "showLegend": true,
            "legendTitle": "",
            "legendPosition": "below",
            "disableTooltip": false,
            "colorScheme": "blueShade1"
          },
          "label": "Packages - Status wise"
        },
        "inputs": [{
          "index": [
            "Status"
          ],
          "column_names": [],
          "column_value": "Package Id",
          "aggregate": ['Package Id', 'count', '', 'normal_aggregation'],
          "filter_pairs": [
            ["Status", ["WORK_IN_PROGRESS", "COMPLETED", "ON_HOLD", "CANCELLED", "NOT_PROCESSABLE"], "="],
          ]
        }],
        "layout": {
          "position_x": 5,
          "position_y": 1,
          "w": 8,
          "h": 2
        }
      },
      {
        "chartId": "chart-6",
        "clientId": "Angieon_uuid",
        "title": "Daily Incoming vs Completed",
        "source": 'angeionData',
        "session_id": "",
        "chart_type": "Line",
        "property": {
          "common": {
            "uid": "line_1",
            "showLegend": false,
            "legendTitle": "",
            "legendPosition": "right",
            "disableTooltip": false,
            "colorScheme": "singleGreen",
            "isTab": true,
            "filterList": ["Incoming", "Completed"],
            "tabBGColor": "#6c74e5",
            "tabColor": "#fff",
          },
          "showXAxis": true,
          "showYAxis": true,
          "showGridLines": true,
          "roundDomains": true,
          "rotateXTicks": false,
          "useGradients": true,
          "hideBarIfZero": false,
          "showDataLabel": true,
          "roundEdges": false,
          "wrapTicks": true,
          "showXAxisLabel": true,
          "xAxisLabel": "Date",
          "showYAxisLabel": true,
          "yAxisLabel": "Packages count",
          "trimXTicks": true,
          "maxXAxisTickLength": 10,
          "trimYTicks": false,
          "maxYAxisTickLength": 12,
          "barPadding": 8,
        },
        "inputs": [{
          "index": ["Status"],
          "filter_pairs": [
            [
              "Status",
              ["NEW"],
              "="
            ]
          ],
          "column_names": [
            "Created Date"
          ],
          "aggregate": [
            "Package Id",
            "count",
            "day",
            "date_aggregation"
          ],
        },
        {
          "index": ["Status"],
          "filter_pairs": [
            [
              "Status",
              ["COMPLETED"],
              "="
            ]
          ],
          "column_names": [
            "Created Date"
          ],
          "aggregate": [
            "Package Id",
            "count",
            "day",
            "date_aggregation"
          ],
        }],
        "layout": {
          "position_x": 1,
          "position_y": 3,
          "w": 12,
          "h": 3
        }
      },
      {
        "chartId": "chart-7-Heat-map",
        "clientId": "Angieon_uuid",
        "title": "Packages created - heat map",
        "source": "angeionData",
        "session_id": "",
        "chart_type": "HeatMapCalendar",
        "property": {
          "minDate": "",
          "maxDate": "",
        },
        "inputs": [{
          "column_names": [
            "Created Date"
          ],
          "filter_pairs": [

          ],
          "aggregate": ['Package Id', 'count', 'day', 'date_aggregation'],
        }],
        "layout": {
          "position_x": 1,
          "position_y": 6,
          "w": 6,
          "h": 2
        }
      },
      {
        "chartId": "chart-8-Heat-map",
        "clientId": "Angieon_uuid",
        "title": "Packages completed - heat map",
        "source": "angeionData",
        "session_id": "",
        "chart_type": "HeatMapCalendar",
        "property": {
          "minDate": "",
          "maxDate": "",
        },
        "inputs": [{
          "column_names": [
            "updatedDateTime"
          ],
          "filter_pairs": [
            ['Status', ['COMPLETED'], '=']
          ],
          "aggregate": ['Package Id', 'count', 'day', 'date_aggregation'],
        }],
        "layout": {
          "position_x": 7,
          "position_y": 6,
          "w": 6,
          "h": 2
        }
      }
    ]
  }
  definitionBackup!: any;

  constructor() {
    this.resetDate();
  }

  loadAllData() {
    this.sharedService.dashboardSessionStored.set(false);
    this.sharedService.showLoading();
    const data = {
      "sourceSet": [
        "angeionData"
      ],
      "client_id": "Angieon_uuid"
    };
    const baseUrl = environment.baseUrlDashboard;
    this.apiService.dashboardPost(baseUrl + '/load_multiple_data', data).subscribe({
      next: (data) => {
        if (data) {
          const uniqueCols = data.sources?.angeionData?.unique_columns;
          if (uniqueCols) {
            this.sharedService.dashboardCaseList.set(uniqueCols['Case Name']['Case Name']);
          }
          if (data.sessions_dict) {
            sessionStorage.setItem('session_dict', JSON.stringify(data.sessions_dict));
            localStorage.setItem('sources', JSON.stringify(data.sources));
            //this.sharedService.isSessionStored.set(true);
          }
          this.sharedService.dashboardSessionStored.set(true);
        }
        this.sharedService.hideLoading();
      },
      error: (err) => {
        console.error('Error fetching data', err);
        this.sharedService.hideLoading();
      },
      complete: () => {
       this.sharedService.hideLoading();
      },
    });
  }

  resetDate() {
    this.dateTo = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    let dt = new Date();
    dt.setMonth(dt.getMonth() - 3)

    this.dateFrom = this.datePipe.transform(dt, 'yyyy-MM-dd');
    this.maxDate = this.dateTo;
  }

  ngOnInit() {
    this.resetDate();
    let definitionDeepCopy = Object.assign({}, this.pageDefinition);
    const obj = definitionDeepCopy.graphs.filter((item: any) => item.chartId === 'chart-7-Heat-map' || item.chartId === 'chart-8-Heat-map');
    obj.map((item: any) => {
      item.property.minDate = this.dateFrom;
      item.property.maxDate = this.dateTo;
    })


    this.pageDefinition = definitionDeepCopy;


    this.definitionBackup = JSON.parse(JSON.stringify(this.pageDefinition));
    if (!this.sharedService.dashboardSessionStored()) {
      this.loadAllData();
    }
    this.applyFilter();
  }

  applyFilter(event?: any) {
    if (event) {
      this.dateFrom = event.dateFrom;
      this.dateTo = event.dateTo;
      this.caseName = event['Case Name'];
    }

    this.pageDefinition = JSON.parse(JSON.stringify(this.definitionBackup));
    let definitionDeepCopy = Object.assign({}, this.pageDefinition);
    const obj = definitionDeepCopy.graphs.filter((item: any) => item.chartId === 'chart-7-Heat-map' || item.chartId === 'chart-8-Heat-map');
    obj.map((item: any) => {
      item.property.minDate = this.dateFrom;
      item.property.maxDate = this.dateTo;
    })

    definitionDeepCopy.graphs.map((graph: any) => {
      if (graph && graph.chartId !== 'chart-new') {
        let filters: (string | string[])[][] = [];
        if (this.dateFrom && this.dateTo) {
          let arr = [];
          if (graph.chartId === 'chart-8-Heat-map') {
            arr.push("updatedDateTime");
          }
          else {
            arr.push("Created Date");
          }
          arr.push([this.dateFrom, this.dateTo]);
          arr.push("<>");
          filters.push(arr);
        }
        if (this.caseName) {
          let arr = [];
          arr.push("Case Name");
          arr.push([this.caseName]);
          arr.push("=");
          filters.push(arr);
        }

        graph.inputs.forEach((input: any) => {
          if (input.filter_pairs) {
            let filterCopy = JSON.parse(JSON.stringify(filters));
            input.filter_pairs.forEach((item: any) => {
              if (item) {
                filterCopy.push(item);
              }
            })
            input.filter_pairs = [];
            input.filter_pairs = filterCopy;
          }
          if (graph.chart_type === 'numCards') {
            if (input.hasOwnProperty('filter_pairs_2')) {
              let filterCopy = JSON.parse(JSON.stringify(filters));
              input.filter_pairs_2.forEach((item: any) => {
                if (item) {
                  filterCopy.push(item);
                }
              })
              input.filter_pairs_2 = [];
              input.filter_pairs_2 = filterCopy;
            }
          }

        })
      }
    });
    this.pageDefinition = definitionDeepCopy; // To make the changedetection works
  }

  resetFilter() {
    this.resetDate();
    this.caseName = '';
    this.pageDefinition = JSON.parse(JSON.stringify(this.definitionBackup));
    this.applyFilter();
  }
}
