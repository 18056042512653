<div class="filter-area">
    <div class="filter-wrapper">
        <div class="toggle-area">
            <span>Daily</span>
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isMonthly"
                    (change)="reportData = []; selectedDate = ''; selectedMonth=''">
                <span class="slider round"></span>
            </label>
            <span>Monthly</span>
        </div>
        <div *ngIf="!isMonthly">
            <input type="date" class="input" [(ngModel)]="selectedDate">
        </div>
        <div *ngIf="isMonthly">
            <div>
                <select class="input" [(ngModel)]="selectedMonth">
                    <option value="">Select month</option>
                    @for (case of monthsList; track case){
                    <option [ngValue]="case">{{case.month}}</option>
                    }
                </select>
            </div>
        </div>
        <div>
            <select class="input" [(ngModel)]="selectedUser">
                <option value="">Select user</option>
                @for (case of coderNameList; track case){
                <option [value]="case">{{case}}</option>
                }
            </select>
        </div>
        <div class="btn-wrapper">
            <button class="btn btn-primary" (click)="applyProdReportFilter()">Apply</button>
            <!-- <button class="btn btn-secondary" (click)="resetProdReportFilter()">Reset</button> -->
        </div>
    </div>
    <div class="export-wrapper">
        <button class="btn" (click)="exportReport()">Export</button>
    </div>
</div>

<div class="grid">
    <ng-container>
        <div class="grid-header">Created Date</div>
        <div class="grid-header">User Name</div>
        <div class="grid-header">Number of Images Processed</div>
        <div class="grid-header">Number of Claims Processed</div>
        <div class="grid-header">Source System</div>
        <div class="grid-header">Claim Type</div>
    </ng-container>
    @for(report of reportData; track report.id; let i = $index) {
    <ng-container>
        <div>{{isMonthly ? selectedMonth.month : selectedDate}}</div>
        <div>{{report.coderName}}</div>
        <div>{{report.numImages}}</div>
        <div>{{report.numClaimIds}}</div>
        <div>{{report.sourceSystem}}</div>
        <div>{{report.claimType}}</div>
    </ng-container>
    }
    @empty {
    <div class="no-records">No records</div>
    }
</div>
<app-pagination [totalPackageLength]="totalRecords" [pageSize]="pageSize" [listCount]="reportData.length"
    (loadPageEmit)="paginationNavigation($event)"></app-pagination>