<div class="container">
    <div class="head">Unprocessable File Details</div>
    <div class="details">
        <div class="box">
            <div class="label">Case name</div>
            <div class="value">{{package.caseName}}</div>
        </div>
        <div class="box">
            <div class="label">Case ID</div>
            <div class="value">{{package.caseId}}</div>
        </div>
        <div class="box">
            <div class="label">Claim ID</div>
            <div class="value">{{package.claimId}}</div>
        </div>
        <div class="box">
            <div class="label">Package ID</div>
            <div class="value">{{package.packageId}}</div>
        </div>
    </div>
    <div class="table">
        <div class="th">#</div>
        <div class="th">File name</div>
        <div class="th">S3 URL</div>
        <div class="th">Created date</div>
        <div class="th">File status</div>
        @for (doc of package.anPackageDocumentDTOs; track doc.id; let i=$index) {
            <div class="td">{{i+1}}</div>
            <div class="td">{{doc.fileName}}</div>
            <div class="td">{{doc.imageUrl}}</div>
            <div class="td">{{doc.createdDateTime | formatDateTime}}</div>
            <div class="td">{{doc.isProcessed? 'Processed' :'Not processable'}}</div>
        }
    </div>
</div>