import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { IFilter, IFilterPreset } from '../../../../_interfaces/packageList';
import { SharedService } from '../../../../_services/shared.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-preset',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './preset.component.html',
  styleUrl: './preset.component.scss',
})
export class PresetComponent implements OnInit {
  @Input() filterPresets: IFilterPreset[] = [];
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onSave = new EventEmitter<IFilterPreset[]>();
  @Output() onLoad = new EventEmitter<IFilterPreset>();
  newPreset: IFilterPreset;
  sharedService = inject(SharedService);
  constructor() {
    this.newPreset = {
      filter: this.sharedService.initializeFilter(),
      id: Date.now(),
      name: '',
    };
  }

  ngOnInit(): void {}

  closePopup() {
    this.onClose.emit(true);
  }
  save() {
    this.newPreset.id = Date.now();
    this.newPreset.filter = this.sharedService.filter();
    if (!this.filterPresets.length) {
      this.filterPresets = [];
    }
    this.filterPresets.push(this.newPreset);
    this.newPreset = {
      id: 0,
      name: '',
      filter: this.sharedService.initializeFilter(),
    };
    this.onSave.emit(this.filterPresets);
  }
  remove(preset: IFilterPreset) {
    this.filterPresets = this.filterPresets.filter((e) => e.id != preset.id);
    this.onSave.emit(this.filterPresets);
  }
  load(preset: IFilterPreset) {
    console.log({ preset });

    if (preset) {
      this.onLoad.emit(preset);
    }
  }
}
