<div class="cp-overlay">
    <div class="cp-container">
        <div class="f-head">  <div class="filter-buttons">Filter</div></div>
      <div class="cp-header">
        Manage filter preset
        <div class="item"></div>
      </div>
      
      <div class="cp-body">
        <div class="list" *ngIf="filterPresets.length">
          <div class="item heading">
            
            <div class="name">Name
            </div>
            <div class="action">Action
            </div>
          </div>
          <div class="item" *ngFor="let preset of filterPresets; let i=index;">
           
            <div class="name">{{preset.name}}</div>
            <div class="action">
              <div title="Load">
                <svg class="close-icon" (click)="load(preset)">
                    <use [attr.xlink:href]="'assets/icon-sprite.svg#upload-icon'" />
                  </svg>
                </div>
                <div title="Remove">
                  <svg class="close-icon" (click)="remove(preset)">
                    <use [attr.xlink:href]="'assets/icon-sprite.svg#remove-icon'" />
                  </svg>
                </div>
              <!-- <button class="btn btn-1" (click)="load(preset)">Load</button>
              <button class="btn btn-3" (click)="remove(preset)">Remove</button> -->
            </div>
          </div>
        </div>
        <div class="save">
          <div class="form">
            <label class="save-text">Save current filter as </label>
            <input type="text" class="input" title="preset name" [(ngModel)]="newPreset.name">
          </div>
          
        </div>
        <div  class="buttons">
        <button class="btn btn-primary w-100" [disabled]="!newPreset.name" (click)="save()">Save</button>
          <button class="btn btn-secondary w-100" (click)="closePopup()">Close</button>
        </div>
      </div>
      
        
     
    </div>
  </div>
  