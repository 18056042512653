import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SharedService } from '../../../_services/shared.service';
import { FormsModule } from '@angular/forms';
import {
  IDropdown,
  IFilterPreset,
  Status,
} from '../../../_interfaces/packageList';
import { PresetComponent } from './preset/preset.component';
import { ICoder } from '../../../_interfaces/user';
import { CommonModule, DatePipe } from '@angular/common';
import { IStatus } from '../../../_interfaces/status';
import { DropdownComponent } from '../../_common/dropdown/dropdown.component';
import { ApiService } from '../../../_services/api.service';
import { MessageType } from '../../../_interfaces/entity';
import { NavigationEnd, Router } from '@angular/router';

enum ShowBox {
  Filter,
  Preset,
  Null,
}
@Component({
  selector: 'app-listing-header',
  standalone: true,
  imports: [FormsModule, PresetComponent, CommonModule, DropdownComponent],
  providers: [DatePipe],
  templateUrl: './listing-header.component.html',
  styleUrl: './listing-header.component.scss',
})
export class ListingHeaderComponent implements OnInit, OnChanges {
  statusList: IDropdown[] = [];
  filterList: IDropdown[] = [];
  toggleFilter: ShowBox = ShowBox.Null;
  filterPresets: IFilterPreset[] = [];
  coderDropdown: IDropdown[] = [];
  selectedCoder: IDropdown = { id: '', value: '' };
  selectedStatus: IDropdown = { id: '', value: '' };
  selectedPreset: IDropdown = { id: '', value: '' };
  @Output() onFilter = new EventEmitter<boolean>();
  @Output() onGetCaseNameRequest = new EventEmitter<boolean>();
  @ViewChild('filter') filterEl!: ElementRef<HTMLElement>;
  sharedService = inject(SharedService);
  @Input() coders: ICoder[] = [];
  datePipe = inject(DatePipe);
  apiService = inject(ApiService);
  router = inject(Router);
  @Input() isFilterApplied: boolean = false;
  @Input() hasNewPackage: boolean = false;
  headerTitle: string = '';
  isArchived: boolean = false;

  @HostListener('document:click', ['$event'])
  clickOutsideComponent(event: Event) {
    if (event.target) {
      const target: Node = event.target as Node;
      const box3Element =
        this.filterEl.nativeElement.querySelector('.dropdownicon');
      if (!this.filterEl.nativeElement.contains(target)) {
        this.toggleFilter = ShowBox.Null;
      }
    }
  }
  updateHeaderTitle() {
    if (this.router.url.includes('archived-packages')) {
      this.headerTitle = 'Archived Packages';
    } else if (this.sharedService.userview() === 'userview') {
      this.headerTitle = 'My Vault';
    } else {
      this.headerTitle = 'Listing Page';
    }
  }

  constructor() {
    this.selectedStatus = this.sharedService.filter().status?.id
      ? this.sharedService.filter().status
      : { id: '', value: '' };
    this.selectedCoder = this.sharedService.filter().coderId?.id
      ? this.sharedService.filter().coderId
      : { id: '', value: '' };
  }

  ngOnInit(): void {
    if (this.router.url && this.router.url.includes('archived')) {
      this.isArchived = true;
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateHeaderTitle();
      }
    });

    this.updateHeaderTitle();
    this.statusList = this.sharedService.status();
    if (this.isArchived) {
      this.statusList = this.statusList.filter((status) => {
        if (['CANCELLED', 'COMPLETED'].includes(status.id)) {
          return status;
        }
        return false;
      });
    }
    console.log({ statusList: this.statusList });

    this.getFilterPresets();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['coders']?.currentValue) {
      this.coderDropdown = this.coderToDropDown(this.coders);
    }
  }
  done() {
    this.toggleFilter = ShowBox.Null;
    this.onFilter.emit(true);
  }
  reset() {
    this.selectedStatus =
      this.selectedPreset =
      this.selectedCoder =
        { id: '', value: '' };
    let selectedCase: string = '';
    if (this.sharedService.filter().caseId) {
      selectedCase = this.sharedService.filter().caseId;
    }
    this.sharedService.filter.set(this.sharedService.initializeFilter());
    this.sharedService.filter().caseId = selectedCase;
    
    if (this.sharedService.userview() == 'userview') {
      this.sharedService.filter.update((f) => {
        f.coderId = {
          id:
            (this.sharedService.loginUser().user.id as unknown as string) || '',
          value:
            (this.sharedService.loginUser().user.id as unknown as string) || '',
        };
        return f;
      });
    }
    this.toggleFilter = ShowBox.Null;
    this.onFilter.emit(true);
  }

  queryItem() {
    this.done();
  }

  onPresetSelect(preset: IDropdown) {
    if (preset?.value) {
      if (preset.value == 'Save / Manage filter preset') {
        this.toggleFilter = ShowBox.Preset;
      } else {
        // TODO : load preset here
        const filter = this.filterPresets.find((e) => e.name == preset.value);
        if (filter) {
          this.sharedService.filter.set(filter.filter);
        } else {
          this.sharedService.filter.set(this.sharedService.initializeFilter());
        }
        this.done();
      }
    }
  }

  get ShowBox() {
    return ShowBox;
  }
  toggleFilterView() {
    if (this.toggleFilter == ShowBox.Filter) {
      this.toggleFilter = ShowBox.Null;
    } else {
      this.toggleFilter = ShowBox.Filter;
    }
  }
  getFilterPresets() {
    this.filterPresets = JSON.parse(localStorage.getItem('AG_FILTERS') || '{}');
    this.filterList = [];

    if (this.filterPresets?.length) {
      this.filterPresets.forEach((preset) => {
        this.filterList.push({ id: preset.name, value: preset.name });
      });
    }
    this.filterList.push({
      id: 'Save / Manage filter preset',
      value: 'Save / Manage filter preset',
    });
  }

  onFilterPresetSave(filterPresets: IFilterPreset[]) {
    if (filterPresets) {
      this.filterPresets = filterPresets;
      localStorage.setItem('AG_FILTERS', JSON.stringify(filterPresets));
      this.getFilterPresets();
    }
  }

  loadFilterPreset(preset: IFilterPreset) {
    if (preset) {
      this.sharedService.filter.set(preset.filter);
      this.done();
    }
  }
  coderToDropDown(coders: ICoder[]): IDropdown[] {
    return coders.map((e) => {
      return {
        id: e.id,
        value: `${e.firstName} ${e.lastName}`,
      } as IDropdown;
    });
  }
  getNextPackage() {
    //package/next-package
    console.log(this.sharedService.loginUser());

    const userDetails = {
      email: '',
      lastName: this.sharedService.loginUser().user.last_name,
      firstName: this.sharedService.loginUser().user.first_name,
      anCourtexUserDetails: {
        id: this.sharedService.loginUser().user.id,
        firstName: this.sharedService.loginUser().user.first_name,
      },
    };

    this.sharedService.showLoading();
    this.apiService.put(`package/next-package`, userDetails).subscribe({
      next: (response) => {
        if (response.success) {
          if (!response.data?.hasNewPackage) {
            this.sharedService.showToastMessage({
              message: 'No new package is available',
              type: MessageType.error,
            });
            return;
          }
          const packageItem = response.data;
          if (!packageItem.isClassified) {
            this.router.navigate([
              '/packages',
              packageItem.id,
              'classification',
            ]);
          } else {
            this.router.navigate(['/packages', packageItem.id, 'foi']);
          }
        }
      },
      error: (err) => {
        console.log({ err });
        this.sharedService.hideLoading();
        this.sharedService.showToastMessage({
          message: err.message || 'Error',
          type: MessageType.error,
        });
      },
      complete: () => {
        this.sharedService.hideLoading();
      },
    });
  }
  getCaseName() {
    this.onGetCaseNameRequest.emit(true);
  }
}
