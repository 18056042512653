import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  standalone: true,
  imports: [],
  templateUrl: './confirm-popup.component.html',
  styleUrl: './confirm-popup.component.scss',
})
export class ConfirmPopupComponent implements OnInit {
  @Input() message: string = 'Do you want to continue?';
  @Input() heading: string = 'Confirmation';
  @Output() yes = new EventEmitter<boolean>();
  @Output() no = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}
  yesClicked() {
    this.yes.emit(true);
  }
  noClicked() {
    this.no.emit(true);
  }
}
